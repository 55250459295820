import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Autocomplete, TextField } from '@mui/material';


function PinCard({onInputChange, onClick, isSelected}: any) {
  // State variables for design description and design temperature
  const [pinMaterial, setPinMaterial] = useState('');
  const [pinEnabled, setPinEnabled] = useState(true);

  const pinMaterialsList = [
    { label: '42CrMo4'},
    { label: '34CrNiMo6'},
    { label: '30CrNiMo8'},
    { label: 'S235'},
    { label: 'S355'},
    { label: 'C2205 Duplex'},
  ]

  const handleCardClick = () => {
    onClick(); // Invoke the provided onClick function
  };

  const handleChange = () => {
    setPinEnabled(prev => !prev);
  };

  // Handle button input changes (e.g., Design Temperature)
  const handleBtnInputChange = (newValue: any) => {
    onInputChange({ pin_material: newValue.label });
    setPinMaterial(newValue.label)
  };


  return (
    <Card sx={{marginTop: 2, boxShadow: isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none' }} 
    onClick={handleCardClick} >
      <CardContent component="div" sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}>
        <Typography variant="h6">
        <Grid container spacing={0} alignItems={'center'}>
            <Grid sm>
             Pin Material
            </Grid>
            <Grid sm={'auto'}>
              <Checkbox onChange={handleChange}/>
            </Grid>
          </Grid>
        </Typography>
        {!pinEnabled &&
        <Typography variant="body2">
          <Box sx={{ minWidth: 120 }}>
            <Autocomplete 
              fullWidth size='small' 
              freeSolo
              options={pinMaterialsList}
              sx={{ width: 300,  zIndex: 0 }}
              renderInput={(params) => <TextField {...params} label="Pin Material"  sx={{zIndex: 1}} value={pinMaterial}
              />}
              onChange={(event, newValue) => {
                handleBtnInputChange(newValue);
              }}
            />
          </Box>
        </Typography>}
      </CardContent>
    </Card>
  );
}
export default PinCard;

