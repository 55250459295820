import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function DesignCard({ onInputChange, onClick, isSelected}: any) {
  // State variables for design description and design temperature
  const [designTemp, setDesignTemp] = useState('');
  const [designCode, setDesignCode] = useState('');
  const [designDescription, setDesignDescription] = useState('THE MAIN FUNCTION OF THE CONSTRUCTION IS TO: ');
  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState<Boolean>(false)


  useEffect(() => {
    document.getElementById('downloadButton')?.addEventListener('click', () => {setIsDownloadButtonClicked(true);});
  })

  // Handle text input changes (e.g., Design Description)
  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
        setDesignDescription(value);
        onInputChange({ design_description: value });   
  };

  const handleCardClick = () => {
    onClick(); // Invoke the provided onClick function
  };

  // Handle button input changes (e.g., Design Temperature)
  const handleBtnInputChange = (newValue: any) => {
    // Call onInputChange with the updated designTemp value
    onInputChange({ design_temp: newValue });
  };

    // Handle button input changes (e.g., Design Temperature)
    const handleSelectInputChange = (newValue: any) => {
      onInputChange({ design_code: newValue.props.value });
      setDesignCode(newValue.props.value)
    };

  return (
    <Card sx={{ marginTop: 2, minHeight: 374, boxShadow: isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none'}}
    onClick={handleCardClick} >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Design
        </Typography>
        <div>
          {/* Design Temperature Toggle Buttons */}
          <Typography variant="body2" pt={1} pb={1}>Design Temperature</Typography>
          <ToggleButtonGroup
            size='small'
            fullWidth
            color="primary"
            value={designTemp}
            exclusive
            
            // Handle changes in the ToggleButtonGroup
            onChange={(event, newValue) => {
              // Update designTemp state and call handleBtnInputChange
              setDesignTemp(newValue);
              handleBtnInputChange(newValue);
            }}
          >
            <ToggleButton value="-20">-20</ToggleButton>
            <ToggleButton value="-10">-10</ToggleButton>
            <ToggleButton value="0">0</ToggleButton>
            <ToggleButton value="10" >10</ToggleButton>
          </ToggleButtonGroup>
          <FormControl fullWidth size='small' margin='normal'>
            <InputLabel id="designCode">Design Code</InputLabel>
            <Select
              value={designCode}
              label="Design code"
              onChange={(event, newValue) => {
                handleSelectInputChange(newValue);
              }}
              error={isDownloadButtonClicked && designCode === ''}
              >
              <MenuItem value={'DNV C-101'}>DNV C-101</MenuItem>
              <MenuItem value={'DNV 0378'}>DNV 0378</MenuItem>
            </Select>
            
        </FormControl>


          {/* Design Description Text Field */}
          <TextField
            multiline
            fullWidth
            minRows={9}
            size="small"
            margin="normal"
            id="designDescription"
            label="Design Description"
            variant="outlined"
            value={designDescription}
            // Handle changes in the text field
            onChange={handleTextInputChange}
            error={isDownloadButtonClicked && designDescription === '' || isDownloadButtonClicked && designDescription ===  'THE MAIN FUNCTION OF THE CONSTRUCTION IS TO: '}
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default DesignCard;
