import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ClientModal from './ClientModal';
import { Button, Grid } from '@mui/material';

function ProjectCard({ onInputChange, onClick, isSelected, }: any) {

  const [projectNumber, setProjectNumber] = useState('');
  const [projectName, setProjectName] = useState('');
  const [client, setClient] = useState('');
  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState<Boolean>(false)


  useEffect(() => {
    document.getElementById('downloadButton')?.addEventListener('click', () => {setIsDownloadButtonClicked(true);});
  })

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  
  const handleSaveClient = (data: any) => {
    // Handle saving client data received from ClientModal
    console.log('Projectcard received client data:', data);
    onInputChange({ client: data });
    // Update the state or perform any necessary actions with the received data
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCardClick = () => {
    onClick(); // Invoke the provided onClick function
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    switch (id) {
      case 'projectNumber':
        setProjectNumber((prevProjectNumber) => {
          onInputChange({ project_number: value });
          return value;
        });
        break;
      case 'projectName':
        setProjectName((prevProjectName) => {
          onInputChange({ project_name: value });
          return value;
        });
        break;
      case 'client':
        setClient((prevClient) => {
          onInputChange({ client: value });
          return value;
        });
        break;
      default:
        break;
    }
  };

  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 240, boxShadow: isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none'} }
    onClick={handleCardClick} >
       <CardContent>
        <Typography variant="h6" gutterBottom>
          Project
        </Typography>
        <TextField
          fullWidth
          size="small"
          margin="normal"
          id="projectNumber"
          label="Project Number"
          variant="outlined"
          value={projectNumber}
          onChange={handleInputChange}
          error={isDownloadButtonClicked && projectNumber === ''}
        />
        <TextField
          fullWidth
          size="small"
          margin="normal"
          id="projectName"
          label="Project Name"
          variant="outlined"
          value={projectName}
          onChange={handleInputChange}
          error={isDownloadButtonClicked && projectName === ''}
        />
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              id="client"
              label="Client"
              variant="outlined"
              value={client}
              onChange={handleInputChange}
              disabled={true}
              aria-readonly={true}
            />
          </Grid>
          <Grid item xs={"auto"} marginTop={1}>
            <Button variant="outlined" color="primary" onClick={handleOpenModal} >
              +
            </Button>
          </Grid>
        </Grid>
        <ClientModal open={isModalOpen} onClose={handleCloseModal} onClientDataSave={handleSaveClient} />
      </CardContent>
    </Card>
  );
}

export default ProjectCard;
