import React, { useState } from 'react';
import './App.css';
import { Box, ThemeProvider, Grid, Container, FormControl } from "@mui/material";
import { twdTheme } from "./styles/CustomMaterialTheme";
import { styled } from "@mui/material/styles";
import ProjectCard from './components/ProjectCard';
import DesignCard from './components/DesignCard';
import FabricationCard from './components/FabricationCard';
import InfoCard from './components/InfoCard';
import BoltCard from './components/BoltCard';
import PinCard from './components/PinCard';
import DownloadBtn from './components/DownloadBtn';
import BearingCard from './components/BearingCard'; 
import AccessCard from './components/AccessCard';
import NdtCard from './components/NdtCard';
import RevisionCard from './components/RevisionCard';
import CustomTextCard from './components/CustomTextCard';
import FabricationInfo from './components/infotabs/FabricationInfo';
import EmptyInfo from './components/infotabs/EmptyInfo';
import AccessInfo from './components/infotabs/AccessInfo';
import BearingInfo from './components/infotabs/BearingInfo';
import BoltInfo from './components/infotabs/BoltInfo';
import DesignInfo from './components/infotabs/DesignInfo';
import NDTInfo from './components/infotabs/NDTInfo';
import PinInfo from './components/infotabs/PinInfo';
import ProjectInfo from './components/infotabs/ProjectInfo';
import CustomInfo from './components/infotabs/CustomInfo';
import { Navbar } from 'twd-react-components';
import { InputParameters } from './types/inputParameters';
import UploadCard from './components/UploadCard';

// Styling for the Box containing the whole app.
const AppBox = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  backgroundColor: "var(--twd_grey)",
}));

// Define a type for the selected card
type SelectedCard = 'project' | 'design' | 'fabrication' | 'ndt' | 'bolt' | 'pin' | 'bearing' | 'access' | 'revision' | 'custom';

function App() {
  const [inputParameters, setInputParameters] = useState<Partial<InputParameters>>({
    design: {
      design_temp: 0,
      design_description: "",
      design_code: ""
    },
    material: {
      access_steel: "",
      access_grating: "",
      pin_material: "",
      bearing_material: ""
    },
    fabrication: {
      welding_tolerances_linear_angles: "",
      welding_tolerances_straightness: "",
      machining_tolerances_iso_2768_1: "",
      machining_tolerances_iso_2768_2: ""
    },
    assembly: {
      bolts_pretension: "",
      bolts_method: ""
    },
    table1: {
      material_table: []
    },
    table2: {
      ndt_b_test_mpi: 0,
      ndt_b_test_ut_rt: 0,
      ndt_percentage: 0,
    },
    titleBlock: {
      revision: [],
      project_number: "",
      project_name: "",
      client: {
        logo: "",
        name: "",
        address: "",
        postalCode: "",
        country: "",
        phoneNumber: "",
        email: "",
        website: ""
      }
    },
    customText: {
      custom_text: []
    },
    fileInfo: {
      temp_path: "",
      temp_logo_path: "",
      filename: ""
    }
  });

  const [selectedCard, setSelectedCard] = useState<SelectedCard | null>(null);
  const [infoData, setInfoData] = useState<Record<string, any>>({});

  const handleCardClick = (cardName: SelectedCard) => {
    // Toggle selection when clicking on the card
    console.info(cardName + " is selected");
    setSelectedCard((prevSelected) => (prevSelected === cardName ? null : cardName));

    // Update infoData based on the selected card
    const newInfoData = getInfoDataForCard(cardName);
    setInfoData(newInfoData);
  };

  const getInfoDataForCard = (cardName: SelectedCard): Record<string, any> => {
    // Add logic here to return different information based on the selected card
    switch (cardName) {
      case 'project':
        return { component: ProjectInfo };
      case 'design':
        return { component: DesignInfo };
      case 'fabrication':
        return { component: FabricationInfo };
      case 'ndt':
        return { component: NDTInfo };
      case 'bolt':
        return { component: BoltInfo };
      case 'pin':
        return { component: PinInfo };
      case 'bearing':
        return { component: BearingInfo };
      case 'access':
        return { component: AccessInfo };
      case 'custom':
        return { component: CustomInfo };
      default:
        return { component: EmptyInfo };
    }
  };

  const handleCardDataChange = (cardName: keyof InputParameters, newData: any) => {
    console.info(`cardDataChange: Cardname: ${cardName} Data: ${JSON.stringify(newData)}`);
    console.info(`inputparams: ${JSON.stringify(inputParameters)}`);

    setInputParameters((prevData) => ({
      ...prevData,
      [cardName]: { ...prevData[cardName], ...newData },
    }));
  };

  const handleJSONUpload = (newData: any) => {
    console.info(`JSON uploaded data: ${JSON.stringify(newData)}`);
    console.info(`inputparams: ${JSON.stringify(inputParameters)}`);

    setInputParameters(newData);
    console.info(`inputparams: ${JSON.stringify(inputParameters)}`);

  };

  return (
    <ThemeProvider theme={twdTheme}>
      <AppBox className={"app"}>
        <Navbar title='General Notes'/>
        <Container maxWidth={'xl'} sx={{marginTop: '50px', height: 'calc(100% - 60px)', overflow: 'auto'}}>
          <FormControl>
            <Box pt={6} paddingTop={0} paddingLeft={15} paddingRight={15} height={"100%"}>
              <Grid container rowSpacing={1} columnSpacing={0}>
                <Grid item xs={2.5}>
                  <UploadCard onInputChange={(data: any) => handleJSONUpload(data)} />
                  <ProjectCard onInputChange={(data: any) => handleCardDataChange('titleBlock', data)} 
                    onClick={() => handleCardClick('project')}
                    isSelected={selectedCard === 'project'}
                  />
                  <DesignCard onInputChange={(data: any) => handleCardDataChange('design', data)}
                    onClick={() => handleCardClick('design')}
                    isSelected={selectedCard === 'design'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Container style={{ paddingTop: '16px', paddingRight: '18px', paddingLeft: '18px'}}>
                    <Container style={{ maxHeight: '750px', overflow: 'auto', paddingRight: '10px' }} disableGutters={true}>
                      <FabricationCard
                        onInputChange={(data: any) => handleCardDataChange('fabrication', data)}
                        onClick={() => handleCardClick('fabrication')}
                        isSelected={selectedCard === 'fabrication'}
                      />                    
                      <NdtCard
                        onInputChange={(data: any) => handleCardDataChange('table2', data)}
                        onClick={() => handleCardClick('ndt')}
                        isSelected={selectedCard === 'ndt'}
                      />
                      <BoltCard onInputChange={(data: any) => handleCardDataChange('assembly', data)}
                        onClick={() => handleCardClick('bolt')}
                        isSelected={selectedCard === 'bolt'}
                      />
                      <PinCard onInputChange={(data: any) => handleCardDataChange('material', data)}
                        onClick={() => handleCardClick('pin')}
                        isSelected={selectedCard === 'pin'}
                      />
                      <BearingCard onInputChange={(data: any) => handleCardDataChange('material', data)}
                        onClick={() => handleCardClick('bearing')}
                        isSelected={selectedCard === 'bearing'}
                      />
                      <AccessCard onInputChange={(data: any) => handleCardDataChange('material', data)}
                        onClick={() => handleCardClick('access')}
                        isSelected={selectedCard === 'access'}
                      />
                      <CustomTextCard onInputChange={(data: any) => handleCardDataChange('customText', data)}
                        onClick={() => handleCardClick('custom')}
                        isSelected={selectedCard === 'custom'}
                      />
                      <br />
                    </Container>
                  </Container>
                </Grid>
                <Grid item xs={4.5} pb={2}>
                  <InfoCard componentName={infoData}/>
                  <RevisionCard onInputChange={(data: any) => handleCardDataChange('titleBlock', data)}/>
                  <br />
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Box></Box>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <DownloadBtn cardData={inputParameters} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </Container>
      </AppBox>
    </ThemeProvider>
  );
}

export default App;
