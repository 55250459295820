import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


function BearingCard({onInputChange, onClick, isSelected}: any) {
  // State variables for design description and design temperature
  const [BearingMaterial, setBearingMaterial] = useState('');
  const [BearingEnabled, setBearingEnabled] = useState(true);

  const handleChange = () => {
    setBearingEnabled(prev => !prev);
  };

  const handleCardClick = () => {
    onClick(); // Invoke the provided onClick function
  };

  // Handle button input changes (e.g., Design Temperature)
  const handleBtnInputChange = (newValue: any) => {
    onInputChange({ bearing_material: newValue.props.value });
    setBearingMaterial(newValue.props.value)
  };

  return (
    <Card sx={{marginTop: 2, boxShadow: isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none' }} onClick={handleCardClick}>
      <CardContent component="div" sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}>
        <Typography variant="h6">
        <Grid container spacing={0} alignItems={'center'}>
            <Grid sm>
             Bearing Material
            </Grid>
            <Grid sm={'auto'}>
              <Checkbox onChange={handleChange}/>
            </Grid>
          </Grid>
        </Typography>
        {!BearingEnabled &&
        <Typography variant="body2">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size='small' margin='normal'>
              <InputLabel id="demo-simple-select-label">Bearing material</InputLabel>
              <Select
                value={BearingMaterial}
                label="Bearing material"
                onChange={(event, newValue) => {
                  handleBtnInputChange(newValue);
                }}
                >
                  <MenuItem value={"FRLP"}>FRLP</MenuItem>
                  <MenuItem value={"HDPE"}>HDPE</MenuItem>

              </Select>
            </FormControl>
          </Box>
        </Typography>}
      </CardContent>
    </Card>
  );
}
export default BearingCard;

