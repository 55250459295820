import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import imageCompression from 'browser-image-compression';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

interface ClientModalProps {
  open: boolean;
  onClose: () => void;
  onClientDataSave: (data: any) => void; // Add this prop for passing clientData to parent component
}

const ClientModal: React.FC<ClientModalProps> = ({ open, onClose, onClientDataSave }) => {
  const [client, setClientData] = useState({
    logo: '',
    name: '',
    address: '',
    postalCode: '',
    country: '',
    phoneNumber: '',
    email: '',
    website: '',
  });

  const handleInputChange = (field: keyof typeof client, value: string) => {
    setClientData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleLogoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const options = {
          maxWidthOrHeight: 400,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result as string;
          setClientData((prevData) => ({ ...prevData, logo: base64String }));
        };

        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }
  };

  const handleSave = () => {
    // Handle save logic here, e.g., send data to the server
    console.log('Client Data:', client);

    // Pass clientData to the parent component
    onClientDataSave(client);

    // Close the modal
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Client</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill in the client information:</DialogContentText>
        <TextField
          margin='normal'
          label="Client Name"
          fullWidth
          value={client.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
        />
        <TextField
          margin='normal'
          label="Address"
          fullWidth
          value={client.address}
          onChange={(e) => handleInputChange('address', e.target.value)}
        />
        <TextField
          margin='normal'
          label="Postal Code"
          fullWidth
          value={client.postalCode}
          onChange={(e) => handleInputChange('postalCode', e.target.value)}
        />
        <TextField
          margin='normal'
          label="Country"
          fullWidth
          value={client.country}
          onChange={(e) => handleInputChange('country', e.target.value)}
        />
        <TextField
          margin = 'normal'
          label="Phone Number"
          fullWidth
          value={client.phoneNumber}
          onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
        />
        <TextField
          margin = 'normal'
          label="Email"
          fullWidth
          value={client.email}
          onChange={(e) => handleInputChange('email', e.target.value)}
        />
        <TextField
          margin = 'normal'
          label="Website"
          fullWidth
          value={client.website}
          onChange={(e) => handleInputChange('website', e.target.value)}
        />
        <Button component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
            Upload client logo
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={handleLogoChange} // Handle logo change
            />        
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="outlined" onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientModal;
