import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';


function EmptyInfo() {
  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490, overflowY: 'auto'}}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Bolt Pre-tension
        </Typography>
        <Typography variant="body2">
        Choose the default bolt fastening pre-tension method and specify the percentage of yield strength when method is pre-tension.
        </Typography>
      </CardContent>
    </Card>
  );
}

export default EmptyInfo;
