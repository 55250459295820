function InfoCard({ componentName }: any) {

  if (!componentName || !componentName.component) {
    return null;
  }

  return (
      <componentName.component/>
  );
}

export default InfoCard;
