import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';


function EmptyInfo() {
  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490, overflowY: 'auto'}}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Non-destructive Testing
        </Typography>
        <Typography variant="body2">
         Fill in the NDT-B percentage, this value is usually 40 or 20.
        </Typography>
        <CardMedia >
        <img
            src="NdtTable.png"
            alt=""
            style={{ maxWidth: '100%' }}
          />
        </CardMedia>
      </CardContent>
    </Card>
  );
}

export default EmptyInfo;
