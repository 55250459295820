import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';


function FabricationInfo() {
  return (
    <Card sx={{ marginTop: 2, minWidth: 100, height: 490}}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Fabrication Tolerances
        </Typography>
        <Typography variant="body2">
        Fill in the tolerance classes for fabrication.
        <br></br>
        For more info about these classes follow these links: 
        <br></br>
        <a href="https://twdbv.sharepoint.com/Gedeelde%20%20documenten/Library/10%20STANDARDS%20AND%20CODES/ISO%20[Stand]/ISO%2013920,%20Welding%20-General%20tolerances%20for%20welded%20constructions%20-Dimensions%20for%20lengths%20and%20angles(1996)%20NL.pdf">ISO-13920</a>
        <br></br>
        <a href="https://twdbv.sharepoint.com/Gedeelde%20%20documenten/Forms/AllItems.aspx?id=%2FGedeelde%20%20documenten%2FLibrary%2F10%20STANDARDS%20AND%20CODES%2FISO%20%5BStand%5D%2FISO%202768%2D1%2C%20General%20tolerances%20%281989%29%2Epdf&parent=%2FGedeelde%20%20documenten%2FLibrary%2F10%20STANDARDS%20AND%20CODES%2FISO%20%5BStand%5D">ISO-2768-1</a>
        <br></br>
        <a href='https://twdbv.sharepoint.com/Gedeelde%20%20documenten/Forms/AllItems.aspx?id=%2FGedeelde%20%20documenten%2FLibrary%2F10%20STANDARDS%20AND%20CODES%2FISO%20%5BStand%5D%2FISO%202768%2D2%2C%20General%20tolerances%20%281989%29%2Epdf&parent=%2FGedeelde%20%20documenten%2FLibrary%2F10%20STANDARDS%20AND%20CODES%2FISO%20%5BStand%5D'>ISO-2768-2</a>
        </Typography>
        <CardMedia >
        <img
            src="https://formlabs.com/_next/image/?url=https:%2F%2Fformlabs-media.formlabs.com%2Ffiler_public_thumbnails%2Ffiler_public%2Ffb%2Fa9%2Ffba9933f-a661-43e3-9d85-facca246d374%2Fimage4.jpg__1354x0_q85_subsampling-2.jpg&w=1920&q=75"
            alt=""
            style={{ maxWidth: '100%' }}
          />
        </CardMedia>
      </CardContent>
    </Card>
  );
}

export default FabricationInfo;
