import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function FabricationCard({ onInputChange, onClick, isSelected}: any) {
  const [linearDimensions, setLinearDimensions] = React.useState<string>('');
  const [straightnessClass, setStraightnessClass] = React.useState<string>('');
  const [machiningTolerance2768_1, setMachiningTolerance2768_1] = React.useState<string>('');
  const [machiningTolerance2768_2, setMachiningTolerance2768_2] = React.useState<string>('');
  
  const handleCardClick = () => {
    onClick(); // Invoke the provided onClick function
  };

  // Handle button input changes (e.g., Design Temperature)
  const handleDimInputChange = (newValue: any) => {
    onInputChange({ welding_tolerances_linear_angles: newValue });
  };
  const handleStrInputChange = (newValue: any) => {
    onInputChange({ welding_tolerances_straightness: newValue });
  };
  const handleISO1InputChange = (newValue: any) => {
    onInputChange({ machining_tolerances_iso_2768_1: newValue });
  };
  const handleISO2InputChange = (newValue: any) => {
    onInputChange({ machining_tolerances_iso_2768_2: newValue });
  };
  

  return (
    <Card sx={{ marginBottom: 1, minHeight: 240, boxShadow: isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none' }}
    onClick={handleCardClick} >
      <CardContent component="div" sx={{'&.MuiCardContent-root': { paddingBottom: 0 }}}>
        <Typography variant="h6" gutterBottom>
          Fabrication Tolerances
        </Typography>
        <Typography variant="body2">
          <Grid pt={1} container spacing={1} alignItems={'center'}>
            <Grid item sm={7}>
              Linear and angular dimension tolerances for welded assemblies ISO-13920
            </Grid>
            <Grid item sm>
              <ToggleButtonGroup
                fullWidth
                size='small'
                color="primary"
                exclusive
                value={linearDimensions}
                onChange={(event, newValue) => {
                  setLinearDimensions(newValue);
                  handleDimInputChange(newValue);
                }}
              >

                <ToggleButton value="A">A</ToggleButton>
                <ToggleButton value="B">B</ToggleButton>
                <ToggleButton value="C">C</ToggleButton>
                <ToggleButton value="D">D</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            </Grid>
            <Grid pt={1} container spacing={1} alignItems={'center'}>
              <Grid item sm={7}>
              Straightness, flatness parallelism for welded assemblies ISO-13920
              </Grid>
              <Grid item sm>
              <ToggleButtonGroup 
                fullWidth 
                size='small'
                color="primary"
                exclusive
                value={straightnessClass}
                onChange={(event, newValue) => {
                  setStraightnessClass(newValue);
                  handleStrInputChange(newValue);
                }}

              >
                <ToggleButton value="E">E</ToggleButton>
                <ToggleButton value="F">F</ToggleButton>
                <ToggleButton value="G">G</ToggleButton>
                <ToggleButton value="H">H</ToggleButton>
              </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid pt={1} container spacing={1} alignItems={'center'}>
            <Grid item sm={7}>
            Linear and angular dimension tolerances for machining ISO-2768-1
            </Grid>
            <Grid item sm>
            <ToggleButtonGroup 
              fullWidth 
              size='small'
              color="primary"
              exclusive
              value={machiningTolerance2768_1}
              onChange={(event, newValue) => {
                setMachiningTolerance2768_1(newValue);
                handleISO1InputChange(newValue);
              }}

            >
              <ToggleButton value="f">f</ToggleButton>
              <ToggleButton value="m">m</ToggleButton>
              <ToggleButton value="c">c</ToggleButton>
              <ToggleButton value="v">v</ToggleButton>
            </ToggleButtonGroup>
            </Grid>
          </Grid>    
          
          <Grid pt={1} container spacing={1} alignItems={'center'}>
            <Grid item sm={7}>
            Straightness, flatness and parallelism tolerances for machining ISO-2768-2
            </Grid>
            <Grid item sm>
            <ToggleButtonGroup 
              fullWidth 
              size='small'
              color="primary"
              exclusive
              value={machiningTolerance2768_2}
              onChange={(event, newValue) => {
                setMachiningTolerance2768_2(newValue);
                handleISO2InputChange(newValue);
              }}
            >
              <ToggleButton value="H">H</ToggleButton>
              <ToggleButton value="K">K</ToggleButton>
              <ToggleButton value="L">L</ToggleButton>
            </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default FabricationCard;
