import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { fetchGeneralNotesPdf } from "../services/swegApi";

export default function DownloadBtn({ cardData }: any) {
  const [alert, setAlert] = React.useState<{ open: boolean, type: "success" | "error", message: string }>({ open: false, type: "success", message: "" });

  const handleClick = async () => {
    try {
      const pdfName = `${cardData.titleBlock.project_number}-D-000, General Notes.pdf`;
      await fetchGeneralNotesPdf(cardData, pdfName);
      setAlert({ open: true, type: "success", message: "Download completed!" });
    } catch (error) {
      setAlert({ open: true, type: "error", message: `Download failed: ${(error as Error).message}` });
    }
  };

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleClick}>
        PDF
      </Button>
      <Snackbar 
        open={alert.open} 
        autoHideDuration={6000} 
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleAlertClose} severity={alert.type} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
