
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useState, useCallback } from "react";
import { Card, CardContent, Typography, Grid, Fab, TextField } from '@mui/material';

import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function RevisionCard({ onInputChange }: any) {
  // Initialize the state with an array of one revision object
  const [revisions, setRevisions] = useState([
    {
      date: "",
      author: "",
      pr: "",
      app: "",
    },
  ]);

  // Define the addRevision function using useCallbsack to avoid unnecessary re-rendering
  const addRevision = useCallback(() => {
    // Append a new revision object to the state array
    setRevisions((prevRevisions) => [
      ...prevRevisions,
      {
        date: "",
        author: "",
        pr: "",
        app: "",
      },
    ]);
  }, []);

  const removeRevision = useCallback((index:any) => {
      setRevisions((prevRevisions) =>
        prevRevisions.filter((_, i) => i !== index)
      );
  }, []);

  // Define a helper function to handle the change of textfield values
  const handleChange = (index: number, field: string, value: any) => {
    // Update the state array with the new value
    setRevisions((prevRevisions) => {
      // Copy the previous array
      const newRevisions = [...prevRevisions];
      // Update the revision object at the given index
      newRevisions[index] = {
        ...newRevisions[index],
        [field]: value,
      };
      // Return the new array
         onInputChange({ revision: newRevisions });
      return newRevisions;
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Card sx={{ marginTop: 2, minWidth: 100 }}>
      <CardContent sx={{ '&.MuiCardContent-root': { paddingBottom: 1 } }}>
        <Typography variant="h6" gutterBottom>
          <Grid container spacing={0}>
            <Grid sm>Revisions</Grid>
            <Grid sm={"auto"}>
              <Fab sx={{zIndex: 1, width: '36px', height: '36px'}} color="secondary" aria-label='remove' size="small" onClick={() => removeRevision(revisions.length-1)}>
                <RemoveIcon/>
              </Fab>
            </Grid>
            <Grid sm={"auto"} paddingLeft={1}>
              <Fab sx={{zIndex: 1, width: '36px', height: '36px'}} color="secondary" aria-label="add" size="small" onClick={addRevision}>
                <AddIcon/>
              </Fab>
            </Grid>
          </Grid>
        </Typography>
        <Typography>
          {/* Map over the state array to render the grid of textfields */}
          {revisions.map((revision, index) => (
            <Grid container spacing={1} alignItems={"center"} key={index}>
              <Grid item xs={1}>
                 <Typography pl={1} align="right" variant="button">{index}</Typography>
              </Grid>
              <Grid item xs={4}>
                <DateField
                  size="small"
                  margin="normal"
                  label="Date"
                  variant="outlined"
                  format="DD-MM-YYYY"
                  onChange={(date: Date | null) => handleChange(index, 'date', date)}/>
              </Grid>
              <Grid item xs>
                <TextField
                  size="small"
                  margin="normal"
                  label="Author"
                  variant="outlined"
                  value={ (revision.author)}
                  onChange={(e: { target: { value: any; }; }) => handleChange(index, "author", e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  size="small"
                  margin="normal"
                  label="PR"
                  variant="outlined"
                  value={revision.pr}
                  onChange={(e: { target: { value: any; }; }) => handleChange(index, "pr", e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  size="small"
                  margin="normal"
                  label="App"
                  variant="outlined"
                  value={revision.app}
                  onChange={(e: { target: { value: any; }; }) => handleChange(index, "app", e.target.value)}
                />
              </Grid>
            </Grid>
          ))}
        </Typography>
      </CardContent>
    </Card>
    </LocalizationProvider>
  );
}
export default RevisionCard;
