
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

function NdtCard({onInputChange, onClick, isSelected}: any) {
  // State variables for design description and design temperature
  const [ndtPercentage, setNdtPercentage] = useState('');
  const [ndtEnabled, setNdtEnabled] = useState(true);
  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState<Boolean>(false)


  useEffect(() => {
    document.getElementById('downloadButton')?.addEventListener('click', () => {setIsDownloadButtonClicked(true);});
  })
  
  const handleChange = () => {
    setNdtEnabled(prev => !prev);
    onInputChange({ ndt_enabled: ndtEnabled });
  };

  const handleCardClick = () => {
    onClick(); // Invoke the provided onClick function
  };

  // Handle text input changes (e.g., Design Description)
  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    switch (id) {
      case 'ndtPercentage':
        // Use the functional form of setDesignDescription to ensure the previous state is captured
        setNdtPercentage((prev) => {
          // Call onInputChange with the updated designDescription value
          onInputChange({ ndt_percentage: value });
          // Return the updated value for the state
          return value;
        });
        break;
      default:
        break;
    }
  };

  return (
    <Card sx={{marginTop: 2, boxShadow: isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none'}}
      onClick={handleCardClick}>
      <CardContent component="div" sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}>
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item sm={5}>
              <Typography variant='h6' fontStyle={'revert'}>
                Non-destructive Testing
              </Typography>
            </Grid>
            <Grid item sm>
              <Typography variant="body2">
                <TextField size='small'
                  margin='normal' 
                  id="ndtA" 
                  label="NDT-A" 
                  variant="outlined" 
                  value={'100'}
                  disabled
                />
              </Typography>
            </Grid>
            <Grid item sm>
              <Typography variant="body2">
                <TextField size='small'
                  margin='normal' 
                  id="ndtPercentage" 
                  label="NDT-B" 
                  variant="outlined" 
                  value={ndtPercentage}
                  onChange={handleTextInputChange}
                  error={isDownloadButtonClicked && ndtPercentage === ''}
                />
              </Typography>
            </Grid>
            <Grid item sm>
              <Typography variant="body2">
                <TextField size='small'
                  margin='normal' 
                  id="ndtC" 
                  label="NDT-C" 
                  variant="outlined" 
                  value={"5"}
                  disabled
                />
              </Typography>
            </Grid>
          </Grid>
        
      </CardContent>
    </Card>
  );
}
export default NdtCard;


