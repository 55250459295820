import React, { useState, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Snackbar, Alert, AlertColor } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { PDFDocument, PDFName, PDFDict, PDFArray, PDFString, PDFStream, decodePDFRawStream, PDFRawStream } from 'pdf-lib';

interface UploadCardProps {
  onInputChange?: (jsonContent: any) => void;
}

interface AlertState {
  open: boolean;
  message: string;
  type: AlertColor;
}

const UploadCard: React.FC<UploadCardProps> = ({ onInputChange }) => {
  const [alert, setAlert] = useState<AlertState>({ open: false, message: '', type: 'success' });

  const Input = styled('input')({
    display: 'none',
  });

  const extractAnnotations = async (pdfDoc: PDFDocument) => {
    const pages = pdfDoc.getPages();
    const annotations: PDFDict[] = [];
    console.log('Extracting annotations from pages...');

    for (const page of pages) {
      const pageAnnotations = await page.node.Annots();
      if (pageAnnotations && pageAnnotations instanceof PDFArray) {
        console.log(`Page annotations found: ${pageAnnotations.size()}`);
        for (let i = 0; i < pageAnnotations.size(); i++) {
          const annotationRef = pageAnnotations.get(i);
          const annotation = annotationRef ? pdfDoc.context.lookup(annotationRef) as PDFDict : null;
          if (annotation) {
            annotations.push(annotation);
          }
        }
      } else {
        console.log('No annotations found on this page.');
      }
    }

    console.log(`Total annotations extracted: ${annotations.length}`);
    return annotations;
  };

  const extractAttachmentsFromAnnotations = async (pdfDoc: PDFDocument) => {
    const annotations = await extractAnnotations(pdfDoc);
    const attachments = [];
    console.log('Extracting attachments from annotations...');

    for (const annotation of annotations) {
      const subtype = annotation.lookup(PDFName.of('Subtype')) as PDFName;
      if (subtype && subtype.toString() === '/FileAttachment') {
        console.log('FileAttachment annotation found.');
        const fileSpec = annotation.lookup(PDFName.of('FS')) as PDFDict;
        const fileName = fileSpec.lookup(PDFName.of('F')) as PDFString;
        const stream = fileSpec
          .lookup(PDFName.of('EF'), PDFDict)
          .lookup(PDFName.of('F'), PDFStream) as PDFRawStream;

        const attachment = {
          name: fileName.decodeText(),
          data: decodePDFRawStream(stream).decode(),
        };

        console.log(`Attachment extracted: ${attachment.name}`);
        attachments.push(attachment);
      } else {
        console.log('Non-FileAttachment annotation found.');
      }
    }

    console.log(`Total attachments extracted: ${attachments.length}`);
    return attachments;
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(`File selected: ${file.name}, type: ${file.type}`);
      try {
        if (file.type === 'application/pdf') {
          const arrayBuffer = await file.arrayBuffer();
          const pdfDoc = await PDFDocument.load(arrayBuffer);
          console.log('PDF loaded successfully.');
          const attachments = await extractAttachmentsFromAnnotations(pdfDoc);
          const jsonAttachment = attachments.find((attachment) => attachment.name.endsWith('.json'));

          if (jsonAttachment) {
            console.log(`JSON attachment found: ${jsonAttachment.name}`);
            const jsonString = new TextDecoder().decode(jsonAttachment.data);
            const jsonContent = JSON.parse(jsonString);
            if (onInputChange) {
              onInputChange(jsonContent);
            }
            setAlert({ open: true, message: `Extracted ${jsonAttachment.name} from pdf`, type: 'success' });
          } else {
            console.log('No JSON file found in the PDF.');
            setAlert({ open: true, message: 'No JSON file found in the PDF.', type: 'error' });
          }
        } else if (file.type === 'application/json') {
          const text = await file.text();
          const jsonContent = JSON.parse(text);
          if (onInputChange) {
            onInputChange(jsonContent);
          }
          console.log(`JSON file loaded: ${file.name}`);
          setAlert({ open: true, message: `Loaded JSON file: ${file.name}`, type: 'success' });
        } else {
          console.log('Unsupported file type.');
          setAlert({ open: true, message: 'Unsupported file type.', type: 'error' });
        }
      } catch (error) {
        console.error('Error processing file:', error);
        setAlert({ open: true, message: `Error processing file: ${error}`, type: 'error' });
      }
    } else {
      console.log('No file selected.');
      setAlert({ open: true, message: 'No file selected.', type: 'warning' });
    }
  };

  const handleAlertClose = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <div>
      <Button
        component="label"
        variant="contained"
        fullWidth
        startIcon={<CloudUploadIcon />}
        sx={{ mt: 2, borderRadius: '8px' }}
      >
        Upload PDF
        <Input type="file" onChange={handleFileChange} accept=".pdf,application/pdf,.json,application/json" />
      </Button>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleAlertClose} severity={alert.type} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UploadCard;
