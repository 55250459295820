
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

// ... (other imports)

function BoltCard({ onInputChange, onClick, isSelected}: any) {
  const [boltPretension, setBoltPretension] = useState('');
  const [pretensionType, setPretensionType] = useState('');
  const [boltEnabled, setBoltEnabled] = useState(true);
  const [preTenEnabled, setPreTenEnabled] = useState(true);

  // New state variable to track whether the text field should be disabled
  const [isTextFieldDisabled, setIsTextFieldDisabled] = useState(false);

  const handleChange = () => {
    setBoltEnabled((prev) => !prev);
  };

  const handleCardClick = () => {
    onClick(); // Invoke the provided onClick function
  };

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    switch (id) {
      case 'boltPretension':
        setBoltPretension((prev) => {
          onInputChange({ bolts_pretension: value });
          return value;
        });
        break;
      default:
        break;
    }
  };

  const handleBtnInputChange = (newValue: any) => {
    onInputChange({ bolts_method: newValue.props.value });
    setPretensionType(newValue.props.value);

    // Update the state to enable or disable the text field based on the selected pretensionType
    setIsTextFieldDisabled(newValue.props.value === 'Hand Tight');
  };

  return (
    <Card sx={{ marginTop: 2, boxShadow: isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none' }} onClick={handleCardClick} >
      <CardContent component="div" sx={{ paddingTop: 1, '&.MuiCardContent-root': { paddingBottom: 1 } }}>
        <Typography variant="h6">
          <Grid container spacing={0} alignItems={'center'}>
            <Grid sm>
              Bolt Pre-tension
            </Grid>
            <Grid sm={'auto'}>
              <Checkbox onChange={handleChange} />
            </Grid>
          </Grid>
        </Typography>
        {!boltEnabled && (
          <Typography variant="body2">
            <Grid container spacing={1}>
              <Grid item sm>
                <FormControl fullWidth size="small" margin="normal">
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    value={pretensionType}
                    label="Type"
                    onChange={(event, newValue) => {
                      handleBtnInputChange(newValue);
                    }}
                  >
                    <MenuItem value={'Hand Tight'}>Hand Tight</MenuItem>
                    <MenuItem value={'Pre-tension'}>Pre-Tension</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm>
                <TextField
                  size="small"
                  margin="normal"
                  id="boltPretension"
                  label="Pretension %"
                  variant="outlined"
                  value={boltPretension}
                  onChange={handleTextInputChange}
                  disabled={isTextFieldDisabled} // Set the disabled attribute based on the state
                />
              </Grid>
            </Grid>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default BoltCard;
