import { Card, createTheme } from "@mui/material";
import { Shadows } from "@mui/material";
import { ThemeOptions } from "@mui/material";
import { grey } from "@mui/material/colors";

const twdBlue = "#00adef";
const twdDarkBlue = "#00306b";
const twdRed = "#dd1c1a";
const white = "#ffffff";
const black = "#000000";

export const twdTheme = createTheme({
  // default color palette
  shadows: Array(25).fill("none") as Shadows,

  palette: {
    primary: {
      main: twdBlue,
      contrastText: white,
    },
    secondary: {
      main: white,
      light: white,
      contrastText: twdBlue,
    },
    info: {
      main: twdDarkBlue,
      contrastText: white,
    },

  },

  typography: {
    h6: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '18px'

    },
    body2: {
      fontSize: '12px',
      fontWeight: 'lighter'
    },
    body1: {
      fontSize: '12px',
      fontWeight: 'lighter'
    }
  },


  components: {
    // change styles for appbar
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: twdBlue,
          color: white,
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderColor: "#d3d3d3",
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: 8,
          position: "relative",
          zIndex: 0,
        },
      },
    },
    
    MuiToggleButtonGroup: {
      styleOverrides: {
        root:{
          border: 0,

          '& .MuiButtonBase-root': {
            marginRight: '5%',
            border: 0,

            ':last-child': {
              marginRight: 0,
            }
          },
          '& .MuiTouchRipple-root': {
            border: 'solid 1px #d3d3d3',
            borderRadius: '4px',
          }
        }}
    },

    MuiCheckbox: {
      styleOverrides: {
        root:{
          color: "#d3d3d3",
        },
      },
    },

    MuiFab: {
      styleOverrides: {
        root:{
          color: "#d3d3d3",
          colorScheme: white,
          background: white,
        },
      },
    },

  },
});

export default twdTheme;
