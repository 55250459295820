
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useState, useCallback } from "react";
import { Card, CardContent, Typography, Grid, Fab, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';



function CustomTextCard({ onInputChange, isSelected, onClick }: any) {
  // Initialize the state with an array of one revision object
  const [customText, setCustomText] = useState<{ category: string; description: string; }[]>([]);


  // Define the addRevision function using useCallbsack to avoid unnecessary re-rendering
  const addCustomText = useCallback(() => {
    // Append a new revision object to the state array
    setCustomText((prevCustomText) => [
      ...prevCustomText,
      {
        category: "",
        description: "",
      },
    ]);
  }, []);

  const removeCustomText = useCallback((index:any) => {
      setCustomText((prevCustomText) =>
        prevCustomText.filter((_, i) => i !== index)
      );
  }, []);

  // Define a helper function to handle the change of textfield values
  const handleChange = (index: number, field: string, value: any) => {
    // Update the state array with the new value
    setCustomText((prevCustomText) => {
      // Copy the previous array
      const newCustomText = [...prevCustomText];
      // Update the revision object at the given index
      newCustomText[index] = {
        ...newCustomText[index],
        [field]: value,
      };
      // Return the new array
         onInputChange({ custom_text: newCustomText });
      return newCustomText;
    });

    
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Card sx={{ marginTop: 2, minWidth: 100, boxShadow: isSelected ? 'inset 0px 0px 0px 1px var(--twd_aqua)' : 'none' }} onClick={onClick}>
      <CardContent sx={{ '&.MuiCardContent-root': { paddingBottom: 1 } }}>
        <Typography variant="h6" gutterBottom>
          <Grid container spacing={0}>
            <Grid sm>Custom Text</Grid>
            <Grid sm={"auto"}>
              <Fab sx={{zIndex: 1, width: '36px', height: '36px'}} color="secondary" variant="circular" aria-label='remove' size="small" onClick={() => removeCustomText(customText.length-1)}>
                <RemoveIcon/>
              </Fab>
            </Grid>
            <Grid sm={"auto"} paddingLeft={1}>
              <Fab sx={{zIndex: 1, width: '36px', height: '36px'}} color="secondary" variant="circular" aria-label="add" onClick={addCustomText}>
                <AddIcon/>
              </Fab>
            </Grid>
          </Grid>
        </Typography>
        <Typography>
          {/* Map over the state array to render the grid of textfields */}
          {customText.map((customText, index) => (
          <Grid container spacing={1}>
            <Grid item sm={5}>
              <FormControl fullWidth size="small" margin="normal">
                <InputLabel id="category">Category</InputLabel>
                <Select
                  value={customText.category}
                  label="Category"
                  onChange={(e: { target: { value: any; }; }) => handleChange(index, "category", e.target.value)}
                >
                  <MenuItem value={'Detailed Design Drawings'}>Detailed Design Drawings</MenuItem>
                  <MenuItem value={'Dimensions'}>Dimensions</MenuItem>
                  <MenuItem value={'Weight'}>Weight</MenuItem>
                  <MenuItem value={'Design'}>Design</MenuItem>
                  <MenuItem value={'Materials'}>Materials</MenuItem>
                  <MenuItem value={'Fabrication'}>Fabrication</MenuItem>
                  <MenuItem value={'Welding'}>Welding</MenuItem>
                  <MenuItem value={'Weld Inspection'}>Weld Inspection</MenuItem>
                  <MenuItem value={'Coating'}>Coating</MenuItem>
                  <MenuItem value={'Assembly'}>Assembly</MenuItem>
                  <MenuItem value={'Functional Testing'}>Functional Testing</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item sm>
              <TextField
                size="small"
                id = "description"
                margin="normal"
                label="Custom text"
                variant="outlined"
                value={customText.description}
                onChange={(e: { target: { value: any; }; }) => handleChange(index, "description", e.target.value)}
                fullWidth
                inputProps={{style: {textTransform: 'uppercase'}}}
              />
            </Grid>
          </Grid>
          
          ))}
        </Typography>
      </CardContent>
    </Card>
    </LocalizationProvider>
  );
}
export default CustomTextCard;
